import React, { useEffect, useState } from 'react';

import { types as sdkTypes } from './sdkLoader';

const { Money } = sdkTypes;

/* Add a required indicator to a label if the field is required. */
export const addRequiredIndicator = (label, isRequired = false) =>
  isRequired ? (
    <>
      {label} <span style={{ color: 'var(--colorFail)' }}>*</span>
    </>
  ) : (
    label
  );

export const useStateWithLocalStorage = (defaultVal, key) => {
  const [value, setValue] = useState(defaultVal);

  useEffect(() => {
    const storedValue = window.localStorage.getItem(key);
    if (storedValue) {
      setValue(JSON.parse(storedValue));
    }
  }, []);

  const setValueWrapper = val => {
    setValue(val);
    window.localStorage.setItem(key, JSON.stringify(val));
  };

  return [value, setValueWrapper];
};

export const getApiRoot = () => {
  return window.location.hostname === 'localhost'
    ? 'http://localhost:3500'
    : window.location.origin;
};

// This util parses the publicData.tiers array from a listing and basically
// just parses the price text to a money typed object. This is because it's not currently possible to
// store a money type object in publicData field using sdk.
// The currency used should be publicData.attributes.price.currency
export const parseTiers = (tiers, currency) => {
  return tiers?.map(t => ({ ...t, price: new Money(t.price, currency) }));
};

// copied from server/customRoutes/utils.js
const DEFAULT_PROVIDER_COMMISSION = 15;

const isDev =
  process.env.NODE_ENV !== 'production' ||
  process.env.REACT_APP_MARKETPLACE_ROOT_URL === 'https://dev.gotoretreats.com';

export const PLANS = [
  {
    name: 'Free',
    productId: isDev ? 'prod_QgT6Yo9dU0ru5p' : 'prod_QjDmnihE9sPlFF',
    providerCommission: DEFAULT_PROVIDER_COMMISSION,
  },
  {
    name: 'Community',
    productId: isDev ? 'prod_QgT7UKWuFhjIph' : 'prod_QjDo5nHUSnuDOM',
    providerCommission: 7,
  },
  {
    name: 'Advanced',
    productId: isDev ? 'prod_QgT8ICOMORMlDv' : 'prod_QjDq7LTSnXzrnT',
    providerCommission: 3,
  },
];
