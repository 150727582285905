import React from 'react';
import css from './OrderPanel.module.css';
import { formatMoney } from '../../util/currency';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

const CustomTierSelector = ({
  tiers,
  selectedTier,
  setSelectedTier,
  intl,
  standardPrice,
  unitType,
}) => {
  if (!tiers?.length || !standardPrice) return null;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 12,
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <div
        className={classNames(
          css.customSelectorItem,
          !selectedTier ? css.customSelectorItemSelected : null
        )}
        onClick={() => {
          setSelectedTier(null);
        }}
      >
        <div style={{ minWidth: 15 }}>
          <input
            type="radio"
            id={'Standard'}
            name="radioList"
            value={'Standard'}
            checked={!selectedTier}
          />
        </div>
        <div
          className="radio-list-item-content"
          style={{
            overflow: 'hidden',
          }}
        >
          <strong>{'Standard'}</strong>
          <p className={classNames(css.price, css.priceTier)}>
            {formatMoney(intl, standardPrice)}{' '}
            <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
          </p>
        </div>
      </div>

      {tiers?.map(tier => (
        <div
          className={classNames(
            css.customSelectorItem,
            selectedTier === tier.title ? css.customSelectorItemSelected : null
          )}
          onClick={() => {
            if (selectedTier === tier.title) {
              setSelectedTier(null);
              return;
            }
            setSelectedTier(tier.title);
          }}
        >
          <div style={{ minWidth: 15 }}>
            <input
              type="radio"
              id={tier.title}
              name="radioList"
              value={tier.title}
              checked={selectedTier === tier.title}
            />
          </div>
          <div
            className="radio-list-item-content"
            style={{
              overflow: 'hidden',
            }}
          >
            <strong>{tier.title}</strong>
            <p style={{ margin: 0, color: 'var(--colorGrey600)', fontSize: 14 }}>
              {tier.description}
            </p>
            <p className={classNames(css.price, css.priceTier)}>
              {formatMoney(intl, tier.price)}{' '}
              <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CustomTierSelector;
